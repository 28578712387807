<template>
  <div class="card mb-5 mb-xl-10" v-if="isDataReady">
    <div class="card-body pt-9 pb-0">
      <!--begin::Details-->
      <div class="d-flex flex-wrap flex-sm-nowrap mb-3">
        <!--begin::Info-->
        <div class="flex-grow-1">
          <!--begin::Title-->
          <div
            class="
              d-flex
              justify-content-between
              align-items-start
              flex-wrap
              mb-2
            "
          >
            <!--begin:Licensed Distributor Account Info-->
            <div class="d-flex flex-column">
              <!--begin::Name-->
              <div class="d-flex align-items-center mb-2">
                <a
                  href="#"
                  class="text-gray-700 text-hover-primary fs-2 fw-bolder me-1"
                  >{{ pagetitle }}
                </a>
              </div>
              <!--end::Name-->
            </div>
            <!--end:Licensed Distributor Account Info-->
          </div>
          <!--end::Title-->
        </div>
        <!--end::Info-->
      </div>
      <!--end::Details-->

      <div
        class="card mb-5 mb-xl-10"
        id="kt_profile_details_view"
        v-if="isDataReady"
      >
        <!--begin::Card header-->
        <div class="card-header cursor-pointer">
          <!--begin::Card title-->
          <div class="card-title m-0">
            <h3 class="fw-bolder m-0">User List</h3>
          </div>
          <!--end::Card title-->
          <!--begin::Card toolbar-->
          <div class="card-toolbar">
            <a
              href="#"
              class="btn btn-sm btn-flex btn-light-primary"
              data-bs-toggle="modal"
              data-bs-target="#modal_new_client_user"
            >
              <span class="svg-icon svg-icon-3">
                <inline-svg src="/media/icons/duotune/general/gen035.svg" />
              </span>
              Add new user</a
            >
          </div>
          <!--end::Card toolbar-->
          <!--begin::Search-->
          <input
            type="text"
            class="form-control form-control-lg form-control-solid px-5 mb-2"
            v-model="searchClients"
            name="search"
            placeholder="Search by name..."
            data-kt-search-element="input"
          />
          <p class="px-5 mb-5">{{ filterClientUsers.length }} returned</p>
        </div>
        <!--end::Search-->
        <!--begin::Card header-->

        <!--begin::Card body-->
        <div class="card-body pt-0">
          <!--begin::User Container-->
          <UserListTable :filterClientUsers="filterClientUsers"></UserListTable>
          <!--begin::User Container-->
        </div>

        <!--end::Card body-->
      </div>
    </div>
  </div>
  <div v-else>
    <TableSkeleton></TableSkeleton>
  </div>
  <!--<NewClientUserModal :clientId="Number(clientId)"></NewClientUserModal>-->
</template>

<script lang="ts">
import { defineComponent, onMounted, computed, watch, ref } from "vue";
import { useStore } from "vuex";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Actions } from "@/store/enums/StoreEnums";
import TableSkeleton from "@/components/widgets/skeletons/TableSkeleton.vue";
import UserListTable from "@/components/widgets/client/User-List-Table.vue";
import { ClientUsersRequestDto } from "../../../core/types/ClientTypes";
//import NewClientUserModal from "@/components/modals/forms/NewClientUserModal.vue";

export default defineComponent({
  name: "client-user-list",
  props: [
    "clientid",
    "hirestatus",
    "userroletype",
    "currentuserrole",
    "pagetitle",
    "breadcrumb",
  ],
  components: { TableSkeleton, UserListTable },
  setup(props) {
    const store = useStore();
    const clientId = ref<number | string>(Number(props.clientid));
    const isDataReady = ref(false);
    const clientUsers = computed(() => {
      return store.getters.getClientUserList;
    });
    const currentClient = computed(() => {
      return store.getters.getCurrentClientInfo;
    });
    const searchClients = ref("");

    const clientUsersRequestDto = ref<ClientUsersRequestDto>({
      clientId: props.clientid,
      hireStatus: props.hirestatus,
      userRoleType: props.userroletype,
      currentUserRole: props.currentuserrole,
    });

    onMounted(() => {
      setCurrentPageTitle(props.pagetitle);
      setCurrentPageBreadcrumbs(props.breadcrumb, [props.pagetitle]);
    });

    store
      .dispatch(Actions.GET_CLIENT_USERS, clientUsersRequestDto.value)
      .then(() => {
        //
      })
      .catch(() => {
        Swal.fire({
          text: store.getters.getErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText:
            "There was a problem retrieving the clients users.",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      });

    const filterClientUsers = computed(() => {
      return clientUsers.value.filter((clientUser) => {
        return (
          clientUser.name
            .toLowerCase()
            .indexOf(searchClients.value.toLowerCase()) != -1
        );
      });
    });
    watch(clientUsers, () => {
      isDataReady.value = true;
    });
    return {
      isDataReady,
      clientUsers,
      clientId,
      currentClient,
      clientUsersRequestDto,
      searchClients,
      filterClientUsers,
    };
  },
});
</script>


import { defineComponent } from "vue";

export default defineComponent({
  name: "user-list-table",
  props: ["filterClientUsers"],
  components: {},
  setup() {
    return {};
  },
});

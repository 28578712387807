
import { defineComponent, onMounted, computed, watch, ref } from "vue";
import { useStore } from "vuex";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Actions } from "@/store/enums/StoreEnums";
import TableSkeleton from "@/components/widgets/skeletons/TableSkeleton.vue";
import UserListTable from "@/components/widgets/client/User-List-Table.vue";
import { ClientUsersRequestDto } from "../../../core/types/ClientTypes";
//import NewClientUserModal from "@/components/modals/forms/NewClientUserModal.vue";

export default defineComponent({
  name: "client-user-list",
  props: [
    "clientid",
    "hirestatus",
    "userroletype",
    "currentuserrole",
    "pagetitle",
    "breadcrumb",
  ],
  components: { TableSkeleton, UserListTable },
  setup(props) {
    const store = useStore();
    const clientId = ref<number | string>(Number(props.clientid));
    const isDataReady = ref(false);
    const clientUsers = computed(() => {
      return store.getters.getClientUserList;
    });
    const currentClient = computed(() => {
      return store.getters.getCurrentClientInfo;
    });
    const searchClients = ref("");

    const clientUsersRequestDto = ref<ClientUsersRequestDto>({
      clientId: props.clientid,
      hireStatus: props.hirestatus,
      userRoleType: props.userroletype,
      currentUserRole: props.currentuserrole,
    });

    onMounted(() => {
      setCurrentPageTitle(props.pagetitle);
      setCurrentPageBreadcrumbs(props.breadcrumb, [props.pagetitle]);
    });

    store
      .dispatch(Actions.GET_CLIENT_USERS, clientUsersRequestDto.value)
      .then(() => {
        //
      })
      .catch(() => {
        Swal.fire({
          text: store.getters.getErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText:
            "There was a problem retrieving the clients users.",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      });

    const filterClientUsers = computed(() => {
      return clientUsers.value.filter((clientUser) => {
        return (
          clientUser.name
            .toLowerCase()
            .indexOf(searchClients.value.toLowerCase()) != -1
        );
      });
    });
    watch(clientUsers, () => {
      isDataReady.value = true;
    });
    return {
      isDataReady,
      clientUsers,
      clientId,
      currentClient,
      clientUsersRequestDto,
      searchClients,
      filterClientUsers,
    };
  },
});

<template>
  <div>
    <table class="table table-striped gy-3 gs-3">
      <thead>
        <tr class="fw-bolder fs-5 text-gray-800">
          <th>Name</th>
          <th>Email</th>
          <th>Location</th>
          <th>Email Confirmed</th>
          <th>Status</th>
          <th>Employment</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in filterClientUsers"
          :key="item.respondentId"
          :respondentId="'respondent_' + index"
        >
          <td>
            <div class="d-flex flex-column">
              <p class="d-flex align-items-center pt-2 mb-0">
                <strong>{{ item.name }}</strong>
              </p>
              <p class="d-flex align-items-center mb-0" style="font-size: 11px">
                Job Title: {{ item.jobTitle }}
              </p>
              <p class="d-flex align-items-center mb-0" style="font-size: 11px">
                Role: {{ item.role }}
              </p>
            </div>
          </td>
          <td>
            <div class="d-flex flex-column">
              <p class="d-flex align-items-center py-2">
                {{ item.email }}
              </p>
            </div>
          </td>
          <td>
            <div class="d-flex flex-column">
              <p class="d-flex align-items-center py-2">
                {{ item.clientLocation }}
              </p>
            </div>
          </td>
          <td>
            <div class="d-flex flex-column">
              <p class="d-flex align-items-center py-2">
                <span
                  v-show="item.emailConfirmed"
                  class="badge badge-success mx-2"
                  >Verified</span
                ><span
                  v-show="!item.emailConfirmed"
                  class="badge badge-danger mx-2"
                  >Not Verified</span
                >
              </p>
            </div>
          </td>
          <td>
            <div class="d-flex flex-column">
              <p class="d-flex align-items-center py-2">
                <span v-if="!item.archive" class="badge badge-success"
                  >Active</span
                >
                <span v-else class="badge badge-danger">In-Active</span>
              </p>
            </div>
          </td>
          <td>
            <div class="d-flex flex-column">
              <p class="d-flex align-items-center py-2">
                <span
                  v-if="item.hireStatusText === 'hired'"
                  class="badge badge-success"
                  >{{ item.hireStatusText }}</span
                >
                <span
                  v-if="item.hireStatusText === 'pending'"
                  class="badge badge-warning"
                  >{{ item.hireStatusText }}</span
                >
                <span
                  v-if="item.hireStatusText === 'terminated'"
                  class="badge badge-danger"
                  >{{ item.hireStatusText }}</span
                >
                <span
                  v-if="item.hireStatusText === 'nothired'"
                  class="badge badge-danger"
                  >{{ item.hireStatusText }}</span
                >
              </p>
            </div>
          </td>
          <td>
            <!--begin::Edit-->
            <router-link
              :to="{
                name: 'account-overview',
                params: {
                  accountid: item.respondentAspNetUserId,
                  clientid: item.clientId,
                },
              }"
              class="
                btn btn-icon btn-bg-light btn-active-color-primary btn-sm
                me-1
              "
            >
              <span class="svg-icon svg-icon-3">
                <inline-svg
                  src="../media/icons/duotune/art/art005.svg"
                /> </span
            ></router-link>
            <!--end::Edit-->
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "user-list-table",
  props: ["filterClientUsers"],
  components: {},
  setup() {
    return {};
  },
});
</script>
